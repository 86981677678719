import {
  signatureRequestCreated,
  signatureRequestResent,
} from '@uc/analytics-definitions';
import invariant from 'invariant';
import { action, makeObservable } from 'mobx';
import NProgress from 'nprogress';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import logger from 'src/logger';
import {
  archiveSignatureRequest,
  createSignatureRequestFlow,
  duplicateSignatureRequest,
  refreshSignatureRequest,
  resendSignatureRequest,
  signatureRequestCancelRevision,
  signatureRequestStartRevision,
  voidSignatureRequest,
} from 'src/models/transactions/intents';

export default class SignaturePacketsStore {
  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
  }

  get transactions() {
    return this.parent.transactions;
  }

  refreshSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      refreshSignatureRequest(signatureRequestId)
    );
    return result;
  };

  voidSignatureRequest = async ({
    transactionId,
    signatureRequestId,
    voidReason,
  }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      voidSignatureRequest(signatureRequestId, voidReason)
    );
    return result;
  };

  duplicateSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      duplicateSignatureRequest(signatureRequestId)
    );
    try {
      // Wait for analytics before navigating away
      await signatureRequestCreated({
        product: 'documents',
        sub_product: 'esign',
        product_path: 'Files',
      });
    } catch (err) {
      logger.error(
        'Error while sending signatureRequestCreated analytics',
        err
      );
    }
    invariant(result.flow_id, 'Must have flow id in dispatch result.');
    return result.flow_id;
  };

  reviseSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      signatureRequestStartRevision(signatureRequestId)
    );
    invariant(result.flow_id, 'Must have flow id in dispatch result.');
    return result.flow_id;
  };

  cancelSignatureRequestRevision = async ({
    transactionId,
    signatureRequestId,
  }) => {
    await this.transactions.dispatch(
      transactionId,
      signatureRequestCancelRevision(signatureRequestId)
    );
  };

  resendSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      resendSignatureRequest(signatureRequestId)
    );
    signatureRequestResent({
      product: 'documents',
      sub_product: 'esign',
      product_path: 'Business Tracker',
    });
    return result;
  };

  archiveSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      archiveSignatureRequest([
        {
          signatureRequestId,
          archived: true,
        },
      ])
    );
    return result;
  };

  @action.bound
  async launchWizard(router, transactionId, options, analyticsContext = {}) {
    NProgress.start();
    try {
      if (
        options.transactionDocumentsIds &&
        options.transactionDocumentsIds.length
      ) {
        await this.transactions.getOrFetchItemMulti(
          transactionId,
          'TRANSACTION_DOCUMENT',
          options.transactionDocumentsIds
        );
      }

      if (options.tdvIds && options.tdvIds.length) {
        const tdvs = await this.transactions.getOrFetchItemMulti(
          transactionId,
          'TRANSACTION_DOCUMENT_VERSION',
          options.tdvIds
        );
        await this.transactions.getOrFetchItemMulti(
          transactionId,
          'TRANSACTION_DOCUMENT',
          tdvs.map((tdv) => tdv.tdId)
        );
      }

      const { result } = await this.transactions.dispatch(
        transactionId,
        createSignatureRequestFlow({
          callbackPath: window.location.pathname,
          sourceProductPath: analyticsContext.productPath,
          ...options,
        })
      );
      invariant(result.flow_id, 'Must have flow id in dispatch result.');
      try {
        // Wait for analytics before navigating away
        await signatureRequestCreated({
          product: 'documents',
          sub_product: 'esign',
          product_path: analyticsContext.productPath,
        });
      } catch (err) {
        logger.error(
          'Error while sending signatureRequestCreated analytics',
          err
        );
      }
      const { isEmbedded } = window.Glide;
      return navigateToFlow(router, result.flow_id, {
        search: { source_product_path: analyticsContext.productPath },
        // TJ-35583: isEmbedded is used to bypass E2E test
        ...(isEmbedded ? { feature: 'signatures' } : null),
      });
    } catch (err) {
      NProgress.done();
      this.parent.ui.wentWrong(err);
      throw err;
    }
  }
}
