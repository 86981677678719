import multiDownload from 'multi-download';

export async function getBlobFromUrl(url: string) {
  const response = await fetch(url);
  return response.blob();
}

export async function downloadBlob(blob: Blob, filename?: string) {
  const url = URL.createObjectURL(blob);
  await multiDownload(
    [url],
    filename
      ? {
          rename: () => filename,
        }
      : undefined
  );
  URL.revokeObjectURL(url);
}
